export const option = {
  menuBtn: false,
  column: [
    {
      label: '支持类型',
      prop: 'exportType',
      type: 'checkbox',
      dicData: [
        {
          label: '产品导出',
          value: 1
        },
        {
          label: '订单导出',
          value: 2
        }
      ],
      rules: [
        {
          required: true,
          message: '支持类型必选'
        }
      ]
    }
  ]
}